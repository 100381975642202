'use client';

import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FourOFourPage } from '../lib/menus';
import { track } from '../lib/tracker';
import { FeaturedPages } from './modules/FeaturedModule';

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--section-padding) 0 0;
`;

const FigureContainer = styled.div`
  width: 320px;
  height: 140px;
  margin-bottom: var(--spacing-large);
`;

const ErrorMessage = styled.div`
  font-size: 22px;
  font-weight: var(--font-weight-bold);
  letter-spacing: -0.05em;
`;

export interface FourOFourPageProps {
  fourOFour?: FourOFourPage | null;
}

export const Custom404 = ({ fourOFour }: FourOFourPageProps) => {
  const { t } = useTranslation();
  const firedErrorTrackRef = useRef(false);
  useEffect(() => {
    if (firedErrorTrackRef.current) return;

    track({
      event: 'error',
      traits: {
        event_info: {
          error_type: '404',
        },
      },
    });
    firedErrorTrackRef.current = true;
  }, []);

  return (
    <ContentContainer>
      <FigureContainer>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 320 140">
          <path
            fill="#FFAC70"
            d="M110.621 43.9764v54.402h100.27v-54.402c0-24.2008-22.387-43.97636185-50.135-43.97636185-27.748 0-50.135 19.77556185-50.135 43.97636185Z"
            opacity=".8"
          />
          <path
            fill="#FFAC70"
            d="M210.892 96.0236v-54.402H110.621v54.402c0 24.2004 22.388 43.9764 50.136 43.9764 27.747 0 50.135-19.776 50.135-43.9764Z"
            opacity=".8"
          />
          <path fill="#4E49C7" d="M94.5942 0H47.2969v136.216h47.2973V0Z" />
          <path fill="#4E49C7" d="M70 113.514V68.1082H0v45.4058h70Z" />
          <path fill="#4E49C7" d="M47.2973 68.1081H0L47.2973 0v68.1081Z" opacity=".8" />
          <path fill="#FF4B78" d="M319.513 0h-47.297v136.216h47.297V0Z" />
          <path fill="#FF4B78" d="M294.919 113.514V68.1082h-70v45.4058h70Z" />
          <path fill="#FF4B78" d="M272.216 68.1081h-47.297L272.216 0v68.1081Z" opacity=".8" />
        </svg>
      </FigureContainer>
      <ErrorMessage>{t('marketing_404_title')}</ErrorMessage>
      {fourOFour?.modules?.map((fourOFourModule, index) => {
        if (
          fourOFourModule?.__typename ===
          'SiteSettingsFieldGroupFourOFourModulesFeaturedPagesLayout'
        ) {
          return <FeaturedPages key={index} moduleData={fourOFourModule} />;
        } else {
          return <div key={index} />;
        }
      })}
    </ContentContainer>
  );
};
